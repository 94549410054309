import "./App.css";
import Qrcode from "./Qrcode";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./Login";
import toast, { Toaster } from "react-hot-toast";
import ProtectedRoute from "./ProtectedRoute";
import Loader from "./loader";
function App() {
  return (
    <>
      <Toaster />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/loader" element={<Loader />} />
          <Route path="/AD/*" element={<ProtectedRoute user={"admin"} />}>
            <Route path="attendance/*" element={<Qrcode />} />
          </Route>
          <Route
            path="/supervisor/*"
            element={<ProtectedRoute user={"supervisor"} />}
          >
            <Route path="wfhAttendance/*" element={<Qrcode />} />
          </Route>
          <Route path="/user/*" element={<ProtectedRoute user={"employee"} />}>
            <Route path="wfh/*" element={<Qrcode />} />
          </Route>
          {/* <Route path="/user/*" element={<ProtectedRoute user={"employee"} />}>
            <Route path="wfh/*" element={<Qrcode />} />
          </Route> */}
          {/* </Route> */}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
