import React, { useState, useEffect } from "react";
import logo from "./images/insignia_logo.svg";
import qrback from "./images/qrback.png";
import QRCode from "react-qr-code";
import axios from "axios";
import { Oval } from "react-loader-spinner";
import bcrypt from "bcryptjs";
// var CryptoJS = require("crypto-js");
import curveBg from "./images/curvedLine.png";
import CryptoJS from "crypto-js";
import classNames from "classnames";
import Dropdown from "./dropdown";
import Loader from "./loader";

const Qrcode = () => {
  const [counter, setCounter] = useState(0);
  const [isScanned, setScanned] = useState("");
  const [adminId, setAdminId] = useState();
  const [conn, setConn] = useState();
  const [role, setRole] = useState();
  const [admin, setAdmin] = useState(true);
  const [loader, setLoader] = useState(true);
  // const salt = bcrypt.genSaltSync(10); //generatiing salt
  // console.log("salt", salt);
  var today = new Date(),
    time =
      today.getHours() +
      ":" +
      today.getMinutes() +
      ":" +
      today.getSeconds() +
      " "; //time

  //setTime(true);
  //console.log("time",istime);
  let newTime = time;

  //const token = localStorage.getItem("token");
  const token = "digitaez";
  /// newVal = time + token
  let newVal = newTime.concat(adminId);
  const updateCount = () => {
    // Counter state is incremented
    setCounter(counter + 1);
  };

  const login = () => {
    let token = localStorage.getItem("token");
    //  console.log("Toekn", token);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    //*******   API to detect isSCAN value    *******/

    axios
      .get("http://backend-insignia.digitaezonline.com/api/v1/auth/me", config)
      .then((res) => {
        let data = res.data.data;
        setRole(localStorage.getItem("role"));
        setLoader(false);
        console.log("Res => ", data);
        if (data.role !== "admin") {
          console.log("not admin");
          let officeID = data.officeId;
          setAdminId(officeID);
          setAdmin(false);
        } else {
          setAdminId(res.data.data.officeId);
          console.log("Admin");
          setAdmin(true);
        }
      })

      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    login();
  }, [isScanned]);

  useEffect(() => {
    setTimeout(() => {
      setConn(navigator.onLine);
      updateCount();
    }, 60000); //1 min interval
  }, [counter]);
  console.log("val", newVal);
  //testing
  // Encrypt
  var data = newVal;
  var ciphertext = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    "$2a$10$Bd3q8ZYH7bWLvOIHCIfWXu"
  ).toString();
  //log encrypted data
  var bytes = CryptoJS.AES.decrypt(ciphertext, "$2a$10$Bd3q8ZYH7bWLvOIHCIfWXu");
  var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  console.log("Encrypt Data -", ciphertext);
  console.log("Decypt Data -", decryptedData);
  const conditionalStyles = classNames("App", {
    "blueBackground flex md:flex-col flex-col items-center justify-center  p-8 md:gap-10 py-8":
      !admin,
    "qrbackground flex md:flex-col flex-col items-center justify-center  p-8 md:gap-10 py-8":
      admin,
  });
  return (
    //removed p-12 from below div & md:p-28 also removed
    <>
      <div className="mainWrapper md:fixed  w-full h-full bg-no-repeat bg-cover">
        {loader ? <Loader /> : null}

        <div className="flex flex-row-reverse">
          <Dropdown />
        </div>
        <div className="flex md:flex-row flex-col justify-center items-center h-full gap-12 p-12">
          <div className="w-2/4">
            <div className="flex justify-center md:justify-start">
              <img className="h-48 w-48 my-3 " src={logo} />
            </div>

            <p className="text-base">
              Insignia is an office management app that aims to revolutionize
              the way you manage your business. The app follows a SaaS based
              model and is here to streamline and automate the management
              process, giving you more.
            </p>
            <p className="text-4xl text-heading font-semibold my-4">
              Steps to Mark/Unmark your Attendance:
            </p>
            {/* <ol class="list-decimal">
            <li>Open Insigniapp Application on your Mobile/Device.</li>
          </ol> */}

            <p className="text-base my-2">
              1: Open Insigniapp Application on your Mobile/Device.
            </p>
            <p className="text-base my-2">
              2: Click on the Mark/Unmark button on the home screen.
            </p>
            <p className="text-base my-2">
              3: Scan this QR Code and your Attendance will be Marked/Unmarked.
            </p>
          </div>
          <div className={conditionalStyles}>
            {role !== "admin" ? (
              <p className="text-white md:text-2xl text-lg font-medium text-center">
                Mark Attendance Remotely
              </p>
            ) : null}
            {navigator.onLine ? (
              <>
                <div className="curvedBorder p-4">
                  <div className="qrWhite p-4 justify-center items-center ">
                    <QRCode
                      value={ciphertext}
                      style={{
                        height: "auto",
                        maxWidth: "100%",
                        width: "100%",
                      }}
                      size={256}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <p className="text-rose-600	 font-semibold text-xl ">
                  Check Your Internet Connection
                </p>
                <Oval
                  height="100"
                  width="100"
                  radius="9"
                  color="Lightgreen"
                  ariaLabel="three-dots-loading"
                  wrapperStyle
                  wrapperClass
                />
              </>
            )}
            {/* <div
            style={{
              height: "auto",
              margin: "0 auto",
              // maxWidth: 64,
              width: "100%",
            }}
          >
            <QRCode
              size={256}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={newVal}
              viewBox={`0 0 256 256`}
            />
          </div> */}
            {/* <p className="text-rose-600	 font-semibold text-xl ">
            Check Your Internet Connection
          </p> */}
            <p className="text-white md:text-5xl text-lg font-medium text-center">
              Scan QR Code
            </p>
            <p className="text-white md:text-lg text-base text-center">
              Scan this QR Code and mark your attendance on your Insigniapp
            </p>
            {/* <img src={qrback} className="" /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Qrcode;
