import logo from "./images/insignia_logo.svg";
import { userSchema } from "./LoginSchema";
import { useForm } from "react-hook-form";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { useState } from "react";
import eye from "./images/eye.svg";
import eye_slash from "./images/eye-slash.svg";

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(userSchema),
  });

  const notify = () => toast.success("Successfully toasted!");
  // const history = useHistory();
  const [showPass, setShowPass] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");
  let navigate = useNavigate();

  const HandleForm = (data) => {
    if (!disabled) {
      setDisabled(true);
      setError();

      const promise = axios({
        // Endpoint to send files
        url: `http://backend-insignia.digitaezonline.com/api/v1/auth/attendanceLogin`,
        method: "POST",
        headers: {
          // Add any auth token here
          "Content-Type": "application/json",
          // authorization: "your token comes here",
        },

        // Attaching the form data
        data: data,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);

          // setError(res.message);
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("role", res.data.user.role);
          localStorage.setItem("name", res.data.user.username);
          localStorage.setItem("photo", res.data.user.photo);
          localStorage.setItem("id", res.data.user._id);

          //redirecting

          if (res.data.user.role === "admin") {
            console.log("Successfully Logged In Admin");
            navigate("/AD/attendance");
          } else if (res.data.user.role === "supervisor") {
            navigate("/supervisor/wfhAttendance");
          } else if (res.data.user.role === "employee") {
            console.log("in wfh empl");
            navigate("/user/wfh");
          }
        })

        // Catch errors if any
        .catch((err) => {
          setDisabled(false);
          setError(err.response.data.error);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "Signed in Successfully",
        error: "Unable to Sign in",
      });
    }
  };

  return (
    <div className="grid h-screen w-full place-items-center bg-white overflow-auto">
      <div className=" flex flex-col md:flex-row items-center text-center gap-4 p-6 md:gap-16 justify-start">
        <div className="flex flex-col ">
          <img className="h-32 md:h-48" src={logo} alt="" />
          <div className="p-4">
            <p>
              <span className="font-bold">NOTE: </span>Please consider following
              scenarios:{" "}
            </p>
            <ol class="list-decimal">
              <li>
                <span className="font-bold">For WFH Employees:</span> You can
                use your login details for individual attendance setup
              </li>
              <li>
                <span className="font-bold">For Office Admins:</span> You can
                use your admin login details for office attendance setup
              </li>
            </ol>
          </div>
        </div>
        <div className="flex flex-col gap-3  md:py-3 md:px-16 lg:py-8 lg:gap-6 md:shadow-3xl  md:rounded-3xl">
          <div>
            <h1 className="text-2xl whitespace-nowrap font-bold">Sign In</h1>
            <h3 className="">Access Attendance Portal</h3>
          </div>

          <form
            onSubmit={handleSubmit(HandleForm)}
            className="flex flex-col justify-center gap-2 "
          >
            <div className="flex flex-col text-left gap-2">
              <input
                className="border font-extralight text-sm xl:text-base h-12 px-6 py-5 border-black bg-neutral-200 focus:bg-white rounded-2xl"
                type="email"
                placeholder="Email"
                autoComplete="off"
                {...register("email")}
              />
              <p className="text-red-700">{errors.email?.message}</p>
            </div>
            <div className="flex flex-col text-left gap-2">
              <div className="relative">
                <img
                  className="absolute h-6 w-6 cursor-pointer right-6 top-3"
                  src={showPass ? eye_slash : eye}
                  onClick={() => {
                    setShowPass((prevCheck) => !prevCheck);
                  }}
                  alt=""
                />
                <input
                  className="border font-extralight text-sm xl:text-base h-12 px-6 py-5 border-black bg-neutral-200 focus:bg-white rounded-2xl"
                  type={showPass ? "text" : "password"}
                  autoComplete="off"
                  placeholder="Password"
                  {...register("password")}
                />
              </div>
              <p className="text-red-700">{errors.password?.message}</p>
            </div>

            <p className="text-center text-red-700 mt-2  mb-2 w-full">
              {error}
            </p>

            {/* <div className="error-hndl">
          <p className=" mt-4 error-msg">{errors.email?.message}</p>
          <p className=" mt-4 error-msg">{errors.password?.message}</p>
          <p className="error-msg">{error}</p>
        </div> */}

            <button className="px-4 py-2 bg-black rounded-lg text-white lg:w-2/5 self-center text-base black-hover">
              Sign In
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
